import { reactive as F, toRaw as T, createApp as Ae, nextTick as se, isVNode as de, mergeProps as P, defineComponent as W, ref as q, computed as f, watchEffect as z, createVNode as l, cloneVNode as pe, onMounted as ue, onUnmounted as ce, h as x, Fragment as Ne } from "vue";
const H = {
  TOP_LEFT: "top-left",
  TOP_RIGHT: "top-right",
  TOP_CENTER: "top-center",
  BOTTOM_LEFT: "bottom-left",
  BOTTOM_RIGHT: "bottom-right",
  BOTTOM_CENTER: "bottom-center"
}, M = {
  LIGHT: "light",
  DARK: "dark",
  COLORED: "colored",
  AUTO: "auto"
}, g = {
  INFO: "info",
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
  DEFAULT: "default"
}, _e = {
  BOUNCE: "bounce",
  SLIDE: "slide",
  FLIP: "flip",
  ZOOM: "zoom",
  NONE: "none"
}, fe = {
  dangerouslyHTMLString: !1,
  multiple: !0,
  position: H.TOP_RIGHT,
  autoClose: 5e3,
  transition: "bounce",
  hideProgressBar: !1,
  pauseOnHover: !0,
  pauseOnFocusLoss: !0,
  closeOnClick: !0,
  className: "",
  bodyClassName: "",
  style: {},
  progressClassName: "",
  progressStyle: {},
  role: "alert",
  theme: "light"
}, Ie = {
  rtl: !1,
  newestOnTop: !1,
  toastClassName: ""
}, me = {
  ...fe,
  ...Ie
};
({
  ...fe,
  type: g.DEFAULT
});
var r = /* @__PURE__ */ ((e) => (e[e.COLLAPSE_DURATION = 300] = "COLLAPSE_DURATION", e[e.DEBOUNCE_DURATION = 50] = "DEBOUNCE_DURATION", e.CSS_NAMESPACE = "Toastify", e))(r || {}), J = /* @__PURE__ */ ((e) => (e.ENTRANCE_ANIMATION_END = "d", e))(J || {});
const he = {
  enter: "Toastify--animate Toastify__bounce-enter",
  exit: "Toastify--animate Toastify__bounce-exit",
  appendPosition: !0
}, Oe = {
  enter: "Toastify--animate Toastify__slide-enter",
  exit: "Toastify--animate Toastify__slide-exit",
  appendPosition: !0
}, be = {
  enter: "Toastify--animate Toastify__zoom-enter",
  exit: "Toastify--animate Toastify__zoom-exit"
}, Pe = {
  enter: "Toastify--animate Toastify__flip-enter",
  exit: "Toastify--animate Toastify__flip-exit"
}, ie = "Toastify--animate Toastify__none-enter";
function ge(e, t = !1) {
  var a;
  let n = he;
  if (!e || typeof e == "string")
    switch (e) {
      case "flip":
        n = Pe;
        break;
      case "zoom":
        n = be;
        break;
      case "slide":
        n = Oe;
        break;
    }
  else
    n = e;
  if (t)
    n.enter = ie;
  else if (n.enter === ie) {
    const o = (a = n.exit.split("__")[1]) == null ? void 0 : a.split("-")[0];
    n.enter = `Toastify--animate Toastify__${o}-enter`;
  }
  return n;
}
function Le(e) {
  return e.containerId || String(e.position);
}
const K = "will-unmount";
function $e(e = H.TOP_RIGHT) {
  return !!document.querySelector(`.${r.CSS_NAMESPACE}__toast-container--${e}`);
}
function qe(e = H.TOP_RIGHT) {
  return `${r.CSS_NAMESPACE}__toast-container--${e}`;
}
function Be(e, t, n = !1) {
  const a = [
    `${r.CSS_NAMESPACE}__toast-container`,
    `${r.CSS_NAMESPACE}__toast-container--${e}`,
    n ? `${r.CSS_NAMESPACE}__toast-container--rtl` : null
  ].filter(Boolean).join(" ");
  return B(t) ? t({
    position: e,
    rtl: n,
    defaultClassName: a
  }) : `${a} ${t || ""}`;
}
function Me(e) {
  var E;
  const { position: t, containerClassName: n, rtl: a = !1, style: o = {} } = e, s = r.CSS_NAMESPACE, d = qe(t), v = document.querySelector(`.${s}`), u = document.querySelector(`.${d}`), p = !!u && !((E = u.className) != null && E.includes(K)), m = v || document.createElement("div"), S = document.createElement("div");
  S.className = Be(
    t,
    n,
    a
  ), S.dataset.testid = `${r.CSS_NAMESPACE}__toast-container--${t}`, S.id = Le(e);
  for (const A in o)
    if (Object.prototype.hasOwnProperty.call(o, A)) {
      const _ = o[A];
      S.style[A] = _;
    }
  return v || (m.className = r.CSS_NAMESPACE, document.body.appendChild(m)), p || m.appendChild(S), S;
}
function ee(e) {
  var a, o, s;
  const t = typeof e == "string" ? e : ((a = e.currentTarget) == null ? void 0 : a.id) || ((o = e.target) == null ? void 0 : o.id), n = document.getElementById(t);
  n && n.removeEventListener("animationend", ee, !1);
  try {
    k[t].unmount(), (s = document.getElementById(t)) == null || s.remove(), delete k[t], delete c[t];
  } catch {
  }
}
const k = F({});
function we(e, t) {
  const n = document.getElementById(String(t));
  n && (k[n.id] = e);
}
function te(e, t = !0) {
  const n = String(e);
  if (!k[n]) return;
  const a = document.getElementById(n);
  a && a.classList.add(K), t ? (Re(e), a && a.addEventListener("animationend", ee, !1)) : ee(n), N.items = N.items.filter((o) => o.containerId !== e);
}
function Fe(e) {
  for (const t in k)
    te(t, e);
  N.items = [];
}
function Ce(e, t) {
  const n = document.getElementById(e.toastId);
  if (n) {
    let a = e;
    a = {
      ...a,
      ...ge(a.transition)
    };
    const o = a.appendPosition ? `${a.exit}--${a.position}` : a.exit;
    n.className += ` ${o}`, t && t(n);
  }
}
function Re(e) {
  for (const t in c)
    if (t === e)
      for (const n of c[t] || [])
        Ce(n);
}
function Ue(e) {
  const n = w().find((a) => a.toastId === e);
  return n == null ? void 0 : n.containerId;
}
function re(e) {
  return document.getElementById(e);
}
function xe(e) {
  const t = re(e.containerId);
  return t && t.classList.contains(K);
}
function le(e) {
  var n;
  const t = de(e.content) ? T(e.content.props) : null;
  return t != null ? t : T((n = e.data) != null ? n : {});
}
function De(e) {
  return e ? N.items.filter((n) => n.containerId === e).length > 0 : N.items.length > 0;
}
function ke() {
  if (N.items.length > 0) {
    const e = N.items.shift();
    j(e == null ? void 0 : e.toastContent, e == null ? void 0 : e.toastProps);
  }
}
const c = F({}), N = F({ items: [] });
function w() {
  const e = T(c);
  return Object.values(e).reduce((t, n) => [...t, ...n], []);
}
function He(e) {
  return w().find((n) => n.toastId === e);
}
function j(e, t = {}) {
  if (xe(t)) {
    const n = re(t.containerId);
    n && n.addEventListener("animationend", ne.bind(null, e, t), !1);
  } else
    ne(e, t);
}
function ne(e, t = {}) {
  const n = re(t.containerId);
  n && n.removeEventListener("animationend", ne.bind(null, e, t), !1);
  const a = c[t.containerId] || [], o = a.length > 0;
  if (!o && !$e(t.position)) {
    const s = Me(t), d = Ae(it, t);
    d.mount(s), we(d, s.id);
  }
  o && !t.updateId && (t.position = a[0].position), se(() => {
    t.updateId ? C.update(t) : C.add(e, t);
  });
}
const C = {
  /**
   * add a toast
   * @param _ ..
   * @param opts toast props
   */
  add(e, t) {
    const { containerId: n = "" } = t;
    n && (c[n] = c[n] || [], c[n].find((a) => a.toastId === t.toastId) || setTimeout(() => {
      var a, o;
      t.newestOnTop ? (a = c[n]) == null || a.unshift(t) : (o = c[n]) == null || o.push(t), t.onOpen && t.onOpen(le(t));
    }, t.delay || 0));
  },
  /**
   * remove a toast
   * @param id toastId
   */
  remove(e) {
    if (e) {
      const t = Ue(e);
      if (t) {
        const n = c[t];
        let a = n.find((o) => o.toastId === e);
        c[t] = n.filter((o) => o.toastId !== e), !c[t].length && !De(t) && te(t, !1), ke(), se(() => {
          a != null && a.onClose && (a.onClose(le(a)), a = void 0);
        });
      }
    }
  },
  /**
   * update the toast
   * @param opts toast props
   */
  update(e = {}) {
    const { containerId: t = "" } = e;
    if (t && e.updateId) {
      c[t] = c[t] || [];
      const n = c[t].find((s) => s.toastId === e.toastId), a = (n == null ? void 0 : n.position) !== e.position || (n == null ? void 0 : n.transition) !== e.transition, o = {
        ...e,
        disabledEnterTransition: !a,
        updateId: void 0
      };
      C.dismissForce(e == null ? void 0 : e.toastId), setTimeout(() => {
        i(o.content, o);
      }, e.delay || 0);
    }
  },
  /**
   * clear all toasts in container.
   * @param containerId container id
   */
  clear(e, t = !0) {
    e ? te(e, t) : Fe(t);
  },
  dismissCallback(e) {
    var a;
    const t = (a = e.currentTarget) == null ? void 0 : a.id, n = document.getElementById(t);
    n && (n.removeEventListener("animationend", C.dismissCallback, !1), setTimeout(() => {
      C.remove(t);
    }));
  },
  dismiss(e) {
    if (e) {
      const t = w();
      for (const n of t)
        if (n.toastId === e) {
          Ce(n, (a) => {
            a.addEventListener("animationend", C.dismissCallback, !1);
          });
          break;
        }
    }
  },
  dismissForce(e) {
    if (e) {
      const t = w();
      for (const n of t)
        if (n.toastId === e) {
          const a = document.getElementById(e);
          a && (a.remove(), a.removeEventListener("animationend", C.dismissCallback, !1), C.remove(e));
          break;
        }
    }
  }
}, Ee = F({}), Q = F({});
function ye() {
  return Math.random().toString(36).substring(2, 9);
}
function ze(e) {
  return typeof e == "number" && !isNaN(e);
}
function ae(e) {
  return typeof e == "string";
}
function B(e) {
  return typeof e == "function";
}
function Y(...e) {
  return P(...e);
}
function G(e) {
  return typeof e == "object" && (!!(e != null && e.render) || !!(e != null && e.setup) || typeof (e == null ? void 0 : e.type) == "object");
}
function je(e = {}) {
  Ee[`${r.CSS_NAMESPACE}-default-options`] = e;
}
function Ge() {
  return Ee[`${r.CSS_NAMESPACE}-default-options`] || me;
}
function Ve() {
  return document.documentElement.classList.contains("dark") ? "dark" : "light";
}
var V = /* @__PURE__ */ ((e) => (e[e.Enter = 0] = "Enter", e[e.Exit = 1] = "Exit", e))(V || {});
const Te = {
  containerId: {
    type: [String, Number],
    required: !1,
    default: ""
  },
  clearOnUrlChange: {
    type: Boolean,
    required: !1,
    default: !0
  },
  disabledEnterTransition: {
    type: Boolean,
    required: !1,
    default: !1
  },
  dangerouslyHTMLString: {
    type: Boolean,
    required: !1,
    default: !1
  },
  multiple: {
    type: Boolean,
    required: !1,
    default: !0
  },
  limit: {
    type: Number,
    required: !1,
    default: void 0
  },
  position: {
    type: String,
    required: !1,
    default: H.TOP_LEFT
  },
  bodyClassName: {
    type: String,
    required: !1,
    default: ""
  },
  autoClose: {
    type: [Number, Boolean],
    required: !1,
    default: !1
  },
  closeButton: {
    type: [Boolean, Function, Object],
    required: !1,
    default: void 0
  },
  transition: {
    type: [String, Object],
    required: !1,
    default: "bounce"
  },
  hideProgressBar: {
    type: Boolean,
    required: !1,
    default: !1
  },
  pauseOnHover: {
    type: Boolean,
    required: !1,
    default: !0
  },
  pauseOnFocusLoss: {
    type: Boolean,
    required: !1,
    default: !0
  },
  closeOnClick: {
    type: Boolean,
    required: !1,
    default: !0
  },
  progress: {
    type: Number,
    required: !1,
    default: void 0
  },
  progressClassName: {
    type: String,
    required: !1,
    default: ""
  },
  toastStyle: {
    type: Object,
    required: !1,
    default() {
      return {};
    }
  },
  progressStyle: {
    type: Object,
    required: !1,
    default() {
      return {};
    }
  },
  role: {
    type: String,
    required: !1,
    default: "alert"
  },
  theme: {
    type: String,
    required: !1,
    default: M.AUTO
  },
  content: {
    type: [String, Object, Function],
    required: !1,
    default: ""
  },
  toastId: {
    type: [String, Number],
    required: !1,
    default: ""
  },
  data: {
    type: [Object, String],
    required: !1,
    default() {
      return {};
    }
  },
  type: {
    type: String,
    required: !1,
    default: g.DEFAULT
  },
  icon: {
    type: [Boolean, String, Number, Object, Function],
    required: !1,
    default: void 0
  },
  delay: {
    type: Number,
    required: !1,
    default: void 0
  },
  onOpen: {
    type: Function,
    required: !1,
    default: void 0
  },
  onClose: {
    type: Function,
    required: !1,
    default: void 0
  },
  onClick: {
    type: Function,
    required: !1,
    default: void 0
  },
  isLoading: {
    type: Boolean,
    required: !1,
    default: void 0
  },
  rtl: {
    type: Boolean,
    required: !1,
    default: !1
  },
  toastClassName: {
    type: String,
    required: !1,
    default: ""
  },
  updateId: {
    type: [String, Number],
    required: !1,
    default: ""
  },
  contentProps: {
    type: Object,
    required: !1,
    default: null
  }
}, Qe = {
  autoClose: {
    type: [Number, Boolean],
    required: !0
  },
  isRunning: {
    type: Boolean,
    required: !1,
    default: void 0
  },
  type: {
    type: String,
    required: !1,
    default: g.DEFAULT
  },
  theme: {
    type: String,
    required: !1,
    default: M.AUTO
  },
  hide: {
    type: Boolean,
    required: !1,
    default: void 0
  },
  className: {
    type: [String, Function],
    required: !1,
    default: ""
  },
  controlledProgress: {
    type: Boolean,
    required: !1,
    default: void 0
  },
  rtl: {
    type: Boolean,
    required: !1,
    default: void 0
  },
  isIn: {
    type: Boolean,
    required: !1,
    default: void 0
  },
  progress: {
    type: Number,
    required: !1,
    default: void 0
  },
  closeToast: {
    type: Function,
    required: !1,
    default: void 0
  }
}, We = /* @__PURE__ */ W({
  name: "ProgressBar",
  props: Qe,
  // @ts-ignore
  setup(e, {
    attrs: t
  }) {
    const n = q(), a = f(() => e.hide ? "true" : "false"), o = f(() => ({
      ...t.style || {},
      animationDuration: `${e.autoClose === !0 ? 5e3 : e.autoClose}ms`,
      animationPlayState: e.isRunning ? "running" : "paused",
      opacity: e.hide || e.autoClose === !1 ? 0 : 1,
      transform: e.controlledProgress ? `scaleX(${e.progress})` : "none"
    })), s = f(() => [`${r.CSS_NAMESPACE}__progress-bar`, e.controlledProgress ? `${r.CSS_NAMESPACE}__progress-bar--controlled` : `${r.CSS_NAMESPACE}__progress-bar--animated`, `${r.CSS_NAMESPACE}__progress-bar-theme--${e.theme}`, `${r.CSS_NAMESPACE}__progress-bar--${e.type}`, e.rtl ? `${r.CSS_NAMESPACE}__progress-bar--rtl` : null].filter(Boolean).join(" ")), d = f(() => `${s.value} ${(t == null ? void 0 : t.class) || ""}`), v = () => {
      n.value && (n.value.onanimationend = null, n.value.ontransitionend = null);
    }, u = () => {
      e.isIn && e.closeToast && e.autoClose !== !1 && (e.closeToast(), v());
    }, p = f(() => e.controlledProgress ? null : u), m = f(() => e.controlledProgress ? u : null);
    return z(() => {
      n.value && (v(), n.value.onanimationend = p.value, n.value.ontransitionend = m.value);
    }), () => l("div", {
      ref: n,
      role: "progressbar",
      "aria-hidden": a.value,
      "aria-label": "notification timer",
      class: d.value,
      style: o.value
    }, null);
  }
}), Ke = /* @__PURE__ */ W({
  name: "CloseButton",
  inheritAttrs: !1,
  props: {
    theme: {
      type: String,
      required: !1,
      default: M.AUTO
    },
    type: {
      type: String,
      required: !1,
      default: M.LIGHT
    },
    ariaLabel: {
      type: String,
      required: !1,
      default: "close"
    },
    closeToast: {
      type: Function,
      required: !1,
      default: void 0
    }
  },
  setup(e) {
    return () => l("button", {
      class: `${r.CSS_NAMESPACE}__close-button ${r.CSS_NAMESPACE}__close-button--${e.theme}`,
      type: "button",
      onClick: (t) => {
        t.stopPropagation(), e.closeToast && e.closeToast(t);
      },
      "aria-label": e.ariaLabel
    }, [l("svg", {
      "aria-hidden": "true",
      viewBox: "0 0 14 16"
    }, [l("path", {
      "fill-rule": "evenodd",
      d: "M7.71 8.23l3.75 3.75-1.48 1.48-3.75-3.75-3.75 3.75L1 11.98l3.75-3.75L1 4.48 2.48 3l3.75 3.75L9.98 3l1.48 1.48-3.75 3.75z"
    }, null)])]);
  }
}), Z = ({
  theme: e,
  type: t,
  path: n,
  ...a
}) => l("svg", P({
  viewBox: "0 0 24 24",
  width: "100%",
  height: "100%",
  fill: e === "colored" ? "currentColor" : `var(--toastify-icon-color-${t})`
}, a), [l("path", {
  d: n
}, null)]);
function Ye(e) {
  return l(Z, P(e, {
    path: "M23.32 17.191L15.438 2.184C14.728.833 13.416 0 11.996 0c-1.42 0-2.733.833-3.443 2.184L.533 17.448a4.744 4.744 0 000 4.368C1.243 23.167 2.555 24 3.975 24h16.05C22.22 24 24 22.044 24 19.632c0-.904-.251-1.746-.68-2.44zm-9.622 1.46c0 1.033-.724 1.823-1.698 1.823s-1.698-.79-1.698-1.822v-.043c0-1.028.724-1.822 1.698-1.822s1.698.79 1.698 1.822v.043zm.039-12.285l-.84 8.06c-.057.581-.408.943-.897.943-.49 0-.84-.367-.896-.942l-.84-8.065c-.057-.624.25-1.095.779-1.095h1.91c.528.005.84.476.784 1.1z"
  }), null);
}
function Ze(e) {
  return l(Z, P(e, {
    path: "M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm.25 5a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm2.25 13.5h-4a1 1 0 010-2h.75a.25.25 0 00.25-.25v-4.5a.25.25 0 00-.25-.25h-.75a1 1 0 010-2h1a2 2 0 012 2v4.75a.25.25 0 00.25.25h.75a1 1 0 110 2z"
  }), null);
}
function Xe(e) {
  return l(Z, P(e, {
    path: "M12 0a12 12 0 1012 12A12.014 12.014 0 0012 0zm6.927 8.2l-6.845 9.289a1.011 1.011 0 01-1.43.188l-4.888-3.908a1 1 0 111.25-1.562l4.076 3.261 6.227-8.451a1 1 0 111.61 1.183z"
  }), null);
}
function Je(e) {
  return l(Z, P(e, {
    path: "M11.983 0a12.206 12.206 0 00-8.51 3.653A11.8 11.8 0 000 12.207 11.779 11.779 0 0011.8 24h.214A12.111 12.111 0 0024 11.791 11.766 11.766 0 0011.983 0zM10.5 16.542a1.476 1.476 0 011.449-1.53h.027a1.527 1.527 0 011.523 1.47 1.475 1.475 0 01-1.449 1.53h-.027a1.529 1.529 0 01-1.523-1.47zM11 12.5v-6a1 1 0 012 0v6a1 1 0 11-2 0z"
  }), null);
}
function et() {
  return l("div", {
    class: `${r.CSS_NAMESPACE}__spinner`
  }, null);
}
const oe = {
  info: Ze,
  warning: Ye,
  success: Xe,
  error: Je,
  spinner: et
}, tt = (e) => e in oe;
function nt({
  theme: e,
  type: t,
  isLoading: n,
  icon: a
}) {
  let o;
  const s = {
    theme: e,
    type: t
  };
  return n ? o = oe.spinner() : a === !1 ? o = void 0 : G(a) ? o = T(a) : B(a) ? o = a(s) : de(a) ? o = pe(a, s) : ae(a) || ze(a) ? o = a : tt(t) && (o = oe[t](s)), o;
}
const at = () => {
};
function ot(e, t, n = r.COLLAPSE_DURATION) {
  const { scrollHeight: a, style: o } = e, s = n;
  requestAnimationFrame(() => {
    o.minHeight = "initial", o.height = a + "px", o.transition = `all ${s}ms`, requestAnimationFrame(() => {
      o.height = "0", o.padding = "0", o.margin = "0", setTimeout(t, s);
    });
  });
}
function st(e) {
  const t = q(!1), n = q(!1), a = q(!1), o = q(V.Enter), s = F({
    ...e,
    appendPosition: e.appendPosition || !1,
    collapse: typeof e.collapse > "u" ? !0 : e.collapse,
    collapseDuration: e.collapseDuration || r.COLLAPSE_DURATION
  }), d = s.done || at, v = f(() => s.appendPosition ? `${s.enter}--${s.position}` : s.enter), u = f(() => s.appendPosition ? `${s.exit}--${s.position}` : s.exit), p = f(() => e.pauseOnHover ? {
    onMouseenter: h,
    onMouseleave: I
  } : {});
  function m() {
    const y = v.value.split(" ");
    E().addEventListener(
      J.ENTRANCE_ANIMATION_END,
      I,
      { once: !0 }
    );
    const O = ($) => {
      const U = E();
      $.target === U && (U.dispatchEvent(new Event(J.ENTRANCE_ANIMATION_END)), U.removeEventListener("animationend", O), U.removeEventListener("animationcancel", O), o.value === V.Enter && $.type !== "animationcancel" && U.classList.remove(...y));
    }, b = () => {
      const $ = E();
      $.classList.add(...y), $.addEventListener("animationend", O), $.addEventListener("animationcancel", O);
    };
    e.pauseOnFocusLoss && A(), b();
  }
  function S() {
    if (!E()) return;
    const y = () => {
      const b = E();
      b.removeEventListener("animationend", y), s.collapse ? ot(b, d, s.collapseDuration) : d();
    }, O = () => {
      const b = E();
      o.value = V.Exit, b && (b.className += ` ${u.value}`, b.addEventListener("animationend", y));
    };
    n.value || (a.value ? y() : setTimeout(O));
  }
  function E() {
    return e.toastRef.value;
  }
  function A() {
    document.hasFocus() || h(), window.addEventListener("focus", I), window.addEventListener("blur", h);
  }
  function _() {
    window.removeEventListener("focus", I), window.removeEventListener("blur", h);
  }
  function I() {
    (!e.loading.value || e.isLoading === void 0) && (t.value = !0);
  }
  function h() {
    t.value = !1;
  }
  function R(y) {
    y && (y.stopPropagation(), y.preventDefault()), n.value = !1;
  }
  return z(S), z(() => {
    const y = w();
    n.value = y.findIndex((O) => O.toastId === s.toastId) > -1;
  }), z(() => {
    e.isLoading !== void 0 && (e.loading.value ? h() : I());
  }), ue(m), ce(() => {
    e.pauseOnFocusLoss && _();
  }), {
    isIn: n,
    isRunning: t,
    hideToast: R,
    eventHandlers: p
  };
}
const rt = /* @__PURE__ */ W({
  name: "ToastItem",
  inheritAttrs: !1,
  props: Te,
  // @ts-ignore
  setup(e) {
    const t = q(), n = f(() => !!e.isLoading), a = f(() => e.progress !== void 0 && e.progress !== null), o = f(() => nt(e)), s = f(() => [`${r.CSS_NAMESPACE}__toast`, `${r.CSS_NAMESPACE}__toast-theme--${e.theme}`, `${r.CSS_NAMESPACE}__toast--${e.type}`, e.rtl ? `${r.CSS_NAMESPACE}__toast--rtl` : void 0, e.toastClassName || ""].filter(Boolean).join(" ")), {
      isRunning: d,
      isIn: v,
      hideToast: u,
      eventHandlers: p
    } = st({
      toastRef: t,
      loading: n,
      done: () => {
        C.remove(e.toastId);
      },
      ...ge(e.transition, e.disabledEnterTransition),
      ...e
    });
    return () => l("div", P({
      id: e.toastId,
      class: s.value,
      style: e.toastStyle || {},
      ref: t,
      "data-testid": `toast-item-${e.toastId}`,
      onClick: (m) => {
        e.closeOnClick && u(), e.onClick && e.onClick(m);
      }
    }, p.value), [l("div", {
      role: e.role,
      "data-testid": "toast-body",
      class: `${r.CSS_NAMESPACE}__toast-body ${e.bodyClassName || ""}`
    }, [o.value != null && l("div", {
      "data-testid": `toast-icon-${e.type}`,
      class: [`${r.CSS_NAMESPACE}__toast-icon`, e.isLoading ? "" : `${r.CSS_NAMESPACE}--animate-icon ${r.CSS_NAMESPACE}__zoom-enter`].join(" ")
    }, [G(o.value) ? x(T(o.value), {
      theme: e.theme,
      type: e.type
    }) : B(o.value) ? o.value({
      theme: e.theme,
      type: e.type
    }) : o.value]), e.contentProps ? l("div", {
      "data-testid": "toast-content"
    }, [x(T(e.content), {
      contentProps: e.contentProps
    })]) : l("div", {
      "data-testid": "toast-content"
    }, [G(e.content) ? x(T(e.content), {
      toastProps: T(e),
      closeToast: u,
      data: e.data
    }) : B(e.content) ? e.content({
      toastProps: T(e),
      closeToast: u,
      data: e.data
    }) : e.dangerouslyHTMLString ? x("div", {
      innerHTML: e.content
    }) : e.content])]), (e.closeButton === void 0 || e.closeButton === !0) && l(Ke, {
      theme: e.theme,
      closeToast: (m) => {
        m.stopPropagation(), m.preventDefault(), u();
      }
    }, null), G(e.closeButton) ? x(T(e.closeButton), {
      closeToast: u,
      type: e.type,
      theme: e.theme
    }) : B(e.closeButton) ? e.closeButton({
      closeToast: u,
      type: e.type,
      theme: e.theme
    }) : null, l(We, {
      className: e.progressClassName,
      style: e.progressStyle,
      rtl: e.rtl,
      theme: e.theme,
      isIn: v.value,
      type: e.type,
      hide: e.hideProgressBar,
      isRunning: d.value,
      autoClose: e.autoClose,
      controlledProgress: a.value,
      progress: e.progress,
      closeToast: e.isLoading ? void 0 : u
    }, null)]);
  }
});
let D = 0;
function ve() {
  typeof window > "u" || (D && window.cancelAnimationFrame(D), D = window.requestAnimationFrame(ve), Q.lastUrl !== window.location.href && (Q.lastUrl = window.location.href, C.clear()));
}
const it = /* @__PURE__ */ W({
  name: "ToastifyContainer",
  inheritAttrs: !1,
  props: Te,
  // @ts-ignore
  setup(e) {
    const t = f(() => e.containerId), n = f(() => c[t.value] || []), a = f(() => n.value.filter((o) => o.position === e.position));
    return ue(() => {
      typeof window < "u" && e.clearOnUrlChange && window.requestAnimationFrame(ve);
    }), ce(() => {
      typeof window < "u" && D && (window.cancelAnimationFrame(D), Q.lastUrl = "");
    }), () => l(Ne, null, [a.value.map((o) => {
      const {
        toastId: s = ""
      } = o;
      return l(rt, P({
        key: s
      }, o), null);
    })]);
  }
});
let X = !1;
function Se() {
  const e = [];
  return w().forEach((n) => {
    const a = document.getElementById(n.containerId);
    a && !a.classList.contains(K) && e.push(n);
  }), e;
}
function lt(e) {
  const t = Se().length, n = e != null ? e : 0;
  return n > 0 && t + N.items.length >= n;
}
function dt(e) {
  lt(e.limit) && !e.updateId && N.items.push({
    toastId: e.toastId,
    containerId: e.containerId,
    toastContent: e.content,
    toastProps: e
  });
}
function L(e, t, n = {}) {
  if (X) return;
  n = Y(Ge(), {
    type: t
  }, T(n)), (!n.toastId || typeof n.toastId != "string" && typeof n.toastId != "number") && (n.toastId = ye()), n = {
    ...n,
    content: e,
    containerId: n.containerId || String(n.position)
  };
  const a = Number(n == null ? void 0 : n.progress);
  return a < 0 && (n.progress = 0), a > 1 && (n.progress = 1), n.theme === "auto" && (n.theme = Ve()), dt(n), Q.lastUrl = window.location.href, n.multiple ? N.items.length ? n.updateId && j(e, n) : j(e, n) : (X = !0, i.clearAll(void 0, !1), setTimeout(() => {
    j(e, n);
  }, 0), setTimeout(() => {
    X = !1;
  }, 390)), n.toastId;
}
const i = (e, t) => L(e, g.DEFAULT, t);
i.info = (e, t) => L(e, g.DEFAULT, {
  ...t,
  type: g.INFO
});
i.error = (e, t) => L(e, g.DEFAULT, {
  ...t,
  type: g.ERROR
});
i.warning = (e, t) => L(e, g.DEFAULT, {
  ...t,
  type: g.WARNING
});
i.warn = i.warning;
i.success = (e, t) => L(e, g.DEFAULT, {
  ...t,
  type: g.SUCCESS
});
i.loading = (e, t) => L(e, g.DEFAULT, Y(t, {
  isLoading: !0,
  autoClose: !1,
  closeOnClick: !1,
  closeButton: !1,
  draggable: !1
}));
i.dark = (e, t) => L(e, g.DEFAULT, Y(t, {
  theme: M.DARK
}));
i.remove = (e) => {
  e ? C.dismiss(e) : C.clear();
};
i.clearAll = (e, t) => {
  se(() => {
    C.clear(e, t);
  });
};
i.isActive = (e) => {
  let t = !1;
  return t = Se().findIndex((a) => a.toastId === e) > -1, t;
};
i.update = (e, t = {}) => {
  setTimeout(() => {
    const n = He(e);
    if (n) {
      const a = T(n), {
        content: o
      } = a, s = {
        ...a,
        ...t,
        toastId: t.toastId || e,
        updateId: ye()
      }, d = s.render || o;
      delete s.render, L(d, s.type, s);
    }
  }, 0);
};
i.done = (e) => {
  i.update(e, {
    isLoading: !1,
    progress: 1
  });
};
i.promise = ut;
function ut(e, {
  pending: t,
  error: n,
  success: a
}, o) {
  var m, S, E;
  let s;
  const d = {
    ...o || {},
    autoClose: !1
  };
  t && (s = ae(t) ? i.loading(t, d) : i.loading(t.render, {
    ...d,
    ...t
  }));
  const v = {
    autoClose: (m = o == null ? void 0 : o.autoClose) != null ? m : !0,
    closeOnClick: (S = o == null ? void 0 : o.closeOnClick) != null ? S : !0,
    closeButton: (E = o == null ? void 0 : o.autoClose) != null ? E : null,
    isLoading: void 0,
    draggable: null,
    delay: 100
  }, u = (A, _, I) => {
    if (_ == null) {
      i.remove(s);
      return;
    }
    const h = {
      type: A,
      ...v,
      ...o,
      data: I
    }, R = ae(_) ? {
      render: _
    } : _;
    return s ? i.update(s, {
      ...h,
      ...R,
      isLoading: !1
    }) : i(R.render, {
      ...h,
      ...R,
      isLoading: !1
    }), I;
  }, p = B(e) ? e() : e;
  return p.then((A) => {
    u("success", a, A);
  }).catch((A) => {
    u("error", n, A);
  }), p;
}
i.POSITION = H;
i.THEME = M;
i.TYPE = g;
i.TRANSITIONS = _e;
const ct = {
  install(e, t = {}) {
    ft(t);
  }
};
typeof window < "u" && (window.Vue3Toastify = ct);
function ft(e = {}) {
  const t = Y(me, e);
  je(t);
}
export {
  V as AnimationStep,
  he as Bounce,
  Pe as Flip,
  Oe as Slide,
  C as ToastActions,
  it as ToastifyContainer,
  be as Zoom,
  Ce as addExitAnimateToNode,
  ke as appendFromQueue,
  we as cacheRenderInstance,
  Fe as clearContainers,
  k as containerInstances,
  ct as default,
  j as doAppend,
  w as getAllToast,
  He as getToast,
  Q as globalCache,
  Ee as globalOptions,
  N as queue,
  te as removeContainer,
  i as toast,
  c as toastContainers,
  ft as updateGlobalOptions,
  st as useCssTransition
};
