import { defineStore } from "pinia";

type Panel = "menu" | "search" | "filter" | "travel";

interface GeolocationState {
    latitude: number | null;
    longitude: number | null;
}
interface RecombeeUserData {
    recommId?: string;
    recommIdTtl?: number;
    recomms?: Array<any>;
}

interface User {
    id: string;
    name: string;
    email: string;
    photo?: string;
    phone?: string;
}

interface PanelState {
    menu: boolean;
    search: boolean;
    filter: boolean;
    travel: boolean;
}

interface Exploration {
    id: number | undefined;
    label: string;
    type: string;
    coordinates: any;
    initialDistance: any;
    dates: any;
    pax: any;
    destination: any;
    departure: any;
    generation_steps: any;
}

interface StoreState {
    panels: PanelState;
    aroundMe: boolean;
    isLogged: boolean;
    user: User | null;
    travel: Travel;
    countInteraction: number;
    geolocationStatus: "idle" | "loading" | "success" | "error";
    currentPosition: GeolocationState;
    exploration: Exploration;
    recombee: RecombeeUserData;
}

interface Travel {
    hotelSelectedId: number | null;
    hotelSelectedPrice: number | null;
    totalPrice: number;
    activityPrice: any;
}

export const useStore = defineStore("store", {
    state: (): StoreState => ({
        panels: {
            menu: false,
            search: false,
            filter: false,
            travel: false,
        },
        travel: {
            hotelSelectedId: null,
            hotelSelectedPrice: null,
            activityPrice: [],
            totalPrice: 0,
        },
        isLogged: false,
        user: null,
        countInteraction: 0,
        aroundMe: false,
        geolocationStatus: "idle",
        currentPosition: { latitude: null, longitude: null },
        exploration: {
            id: undefined,
            label: "",
            type: "",
            coordinates: null,
            initialDistance: null,
            dates: null,
            pax: null,
            destination: null,
            departure: null,
            generation_steps: null,
        },
        recombee: {
            recommId: "",
            recommIdTtl: undefined,
            recomms: [],
        },
        events: {},
    }),

    actions: {
        togglePanel(panel: Panel) {
            Object.keys(this.panels).forEach((key) => {
                this.panels[key as Panel] = key === panel
                    ? !this.panels[key as Panel]
                    : false;
            });
        },

        closeAllPanels() {
            Object.keys(this.panels).forEach((key) => {
                this.panels[key as Panel] = false;
            });
        },

        enableAroundMe() {
            this.aroundMe = true;
            const aroundMeCookie = useCookie("aroundMe");
            aroundMeCookie.value = "true";
        },

        disableAroundMe() {
            this.aroundMe = false;
            const aroundMeCookie = useCookie("aroundMe");
            aroundMeCookie.value = "false";
            this.resetCoordinates();
        },

        toggleAroundMe() {
            console.log("Toggling around me...");
            if (this.aroundMe) {
                this.disableAroundMe();
            } else {
                this.enableAroundMe();
            }
        },

        setCoordinates(latitude: number, longitude: number) {
            this.currentPosition = { latitude, longitude };
        },

        resetCoordinates() {
            this.currentPosition = { latitude: null, longitude: null };
        },

        onPositionChange({ latitude, longitude }: GeolocationState) {
            console.log("Position changed:", { latitude, longitude });
        },

        async setUserData(user: User) {
            this.user = user;
            this.isLogged = true;
        },

        async setPhoto(url: string) {
            if (this.user) {
                this.user.photo = url;
            } else {
                console.debug("Cannot set photo: User is not set.");
            }
        },

        async setUsername(name: string) {
            if (this.user) {
                this.user.name = name;
            } else {
                console.debug("Cannot set username: User is not set.");
            }
        },

        async setLogged(value: boolean) {
            this.isLogged = value;
            if (!value) {
                this.user = null;
            }
        },

        async incrementInteraction() {
            this.countInteraction++;
        },
        async setHotelSelected(id: number, price: number) {
            this.travel.hotelSelectedId = id;
            this.travel.hotelSelectedPrice = price;
        },
        async setTotalTravelPrice(price: number) {
            this.travel.totalPrice = price;
        },
        async addActivityPrice(id: number, price: number) {
            this.travel.activityPrice[id] = price;
        },

        async setExploration(params: any) {
            this.exploration = {
                id: params.id,
                label: params.label,
                type: params.type,
                coordinates: params.coordinates || null,
                initialDistance: params.initialDistance || null,
                dates: params.dates || null,
                pax: params.pax || null,
                destination: params.destination || null,
                departure: params.departure || null,
                generation_steps: params.generation_steps || null,
            };
        },

        async increaseExplorationRadius(increment: number | undefined = 100) {
            this.exploration.initialDistance =
                this.exploration.initialDistance + increment;
        },

        async setRecombee(data: Partial<RecombeeUserData>) {
            this.recombee.recommId = data?.recommId;
            this.recombee.recommIdTtl = data?.recomms
                ? Date.now() + 1000 * 60 * 30
                : undefined;
            this.recombee.recomms = data?.recomms;
        },

        clearRecombee() {
            this.recombee = {
                recommId: "",
                recomms: [],
                recommIdTtl: undefined,
            };
        },
        emit(event, payload) {
            if (!this.events[event]) return;
            console.log("Emitting event:", event);
            console.log("Callbacks registered:", this.events[event]); // Debugging output
            this.events[event].forEach((callback) => {
                if (typeof callback === "function") {
                    callback(payload);
                } else {
                    console.error("Callback is not a function:", callback);
                }
            });
        },
        on(event, callback) {
            if (typeof callback !== "function") {
                console.error(
                    `Invalid callback for event "${event}". Expected a function but got:`,
                    callback,
                );
                return;
            }
            if (!this.events[event]) {
                this.events[event] = [];
            }
            this.events[event].push(callback);
        },
        off(event) {
            if (!this.events[event]) return;
            this.events[event] = [];
        },
    },
    persist: true,
});
