<template>
  <component
    :is="icon"
    :class="{
      'nuxt-icon': fontControlled,
      'nuxt-icon--fill': !filled,
    }"
  />
</template>

<script>
export default {
  props: {
    filled: {
      type: Boolean,
      required: false,
      default: false
    },
    fontControlled: {
      type: Boolean,
      required: false,
      default: true
    },
    icon: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: false
    }
  }
};
</script>

<style>
.nuxt-icon{height:1em;margin-bottom:.125em;vertical-align:middle;width:1em}.nuxt-icon--fill,.nuxt-icon--fill *{fill:currentColor}
</style>
