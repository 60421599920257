import * as Sentry from "@sentry/nuxt";

Sentry.init({
    dsn: useRuntimeConfig().public.sentry.dsn,
    environment: useRuntimeConfig().public.sentry.environment,
    sampleRate: useRuntimeConfig().public.sentry.sampleRate,
    //
    // debug: useRuntimeConfig().public.sentry.environment === 'development',
    enabled: process.env.NODE_ENV === "production",
});
