import { getClient } from "@sentry/core";
import { browserTracingIntegration, vueIntegration } from "@sentry/vue";
import { defineNuxtPlugin } from "nuxt/app";
import { reportNuxtError } from "../utils.js";
export default defineNuxtPlugin({
  name: "sentry-client-integrations",
  dependsOn: ["sentry-client-config"],
  async setup(nuxtApp) {
    if (typeof __SENTRY_TRACING__ === "undefined" || __SENTRY_TRACING__) {
      const sentryClient = getClient();
      if (sentryClient && "$router" in nuxtApp) {
        sentryClient.addIntegration(
          browserTracingIntegration({ router: nuxtApp.$router, routeLabel: "path" })
        );
      }
    }
    nuxtApp.hook("app:created", (vueApp) => {
      const sentryClient = getClient();
      if (sentryClient) {
        sentryClient.addIntegration(vueIntegration({ app: vueApp, attachErrorHandler: false }));
      }
    });
    nuxtApp.hook("app:error", (error) => {
      reportNuxtError({ error });
    });
    nuxtApp.hook("vue:error", (error, instance, info) => {
      reportNuxtError({ error, instance, info });
    });
  }
});
